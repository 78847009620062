import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "@src/store/store";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

interface ITxId {
  txId: string;
  name: string;
  time: number;
}

export interface ITransaction {
  pendingMintTransaction: ITxId;
  pendingDelegateTransaction: ITxId;
  pendingDRepRegistrationTransaction: ITxId;
  pendingDRepRetireTransaction: ITxId;
}

const initialTxState = { txId: "", name: "", time: 0 };

const initialState: ITransaction = {
  pendingMintTransaction: initialTxState,
  pendingDelegateTransaction: initialTxState,
  pendingDRepRegistrationTransaction: initialTxState,
  pendingDRepRetireTransaction: initialTxState,
};

export const transactionSlice = createSlice({
  name: "pendingTransactions",
  initialState,
  reducers: {
    setPendingTransactions: (state, action: PayloadAction<ITransaction>) => {
      return { ...action.payload };
    },
    resetMintpendingTransaction: (state) => {
      state.pendingMintTransaction = initialTxState;
    },
    resetDelegatePendingTransaction: (state) => {
      state.pendingDelegateTransaction = initialTxState;
    },
    resetDRepRegistrationPendingTransaction: (state) => {
      state.pendingDRepRegistrationTransaction = initialTxState;
    },
    resetDRepRetirePendingTransaction: (state) => {
      state.pendingDRepRetireTransaction = initialTxState;
    },
  },
});

const transactionPersistConfig = {
  key: "pendingTransactions",
  storage,
  whitelist: [
    "pendingMintTransaction",
    "pendingDelegateTransaction",
    "pendingDRepRegistrationTransaction",
    "pendingDRepRetireTransaction",
  ],
};

const persistedPendingtransactionReducer = persistReducer(
  transactionPersistConfig,
  transactionSlice.reducer,
);
export const selectPendingTransactions = (state: RootState) =>
  state.pendingTransactions;
export const {
  setPendingTransactions,
  resetDelegatePendingTransaction,
  resetMintpendingTransaction,
  resetDRepRegistrationPendingTransaction,
  resetDRepRetirePendingTransaction,
} = transactionSlice.actions;
export default persistedPendingtransactionReducer;
