export default function NepalFlag(props: React.SVGAttributes<{}>) {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M18.5313 13.1663L5.33398 3.83301V21.1663H18.5313L10.6127 13.1663H18.5313Z"
        fill="#22408B"
      />
      <path
        d="M16.6667 12.4995L6 5.29688V20.4995H16.6667L9.04933 12.4995H16.6667Z"
        fill="#EB4545"
      />
      <path
        d="M9.52696 16.5273L9.91763 15.9833L9.25096 16.0486L9.31763 15.382L8.77296 15.7726L8.49696 15.162L8.22096 15.7726L7.6763 15.382L7.7423 16.0486L7.07563 15.9833L7.4663 16.5273L6.85563 16.8033L7.4663 17.0793L7.07563 17.6233L7.7423 17.558L7.6763 18.2246L8.22096 17.834L8.49696 18.4446L8.77296 17.834L9.31763 18.2246L9.25096 17.558L9.91763 17.6233L9.52696 17.0793L10.1376 16.8033L9.52696 16.5273ZM8.4963 11.7626L8.49696 11.764L8.49763 11.7626L8.50963 11.764C9.4883 11.764 10.283 11.0106 10.3656 10.0526C10.1327 10.4861 9.75701 10.8258 9.3023 11.014L9.18563 10.8513L9.59296 10.6673L9.18563 10.4833L9.4463 10.12L9.00096 10.164L9.04563 9.71862L8.68163 9.97929L8.49763 9.57129L8.31363 9.97929L7.94963 9.71862L7.9943 10.164L7.54896 10.12L7.80963 10.4833L7.4023 10.6673L7.80963 10.8513L7.6983 11.0066C7.25195 10.8166 6.8837 10.4801 6.6543 10.0526C6.69255 10.5161 6.90274 10.9485 7.24358 11.2649C7.58441 11.5814 8.03125 11.7589 8.4963 11.7626Z"
        fill="white"
      />
    </svg>
  );
}
