import CrossIcon from "@src/components/icons/cross";
import { useModal } from "../context";
import { Button } from "@headlessui/react";
import LinkIcon from "@src/components/icons/link";
import { toast } from "react-toastify";
import { ToastId } from "@src/constants/toastId";

export default function ShareModal() {
  const { closeModal } = useModal();

  const handleCopy = () => {
    navigator.clipboard.writeText(window.location.href);
    toast.success("Copied to clipboard", { toastId: ToastId.SUCCESS_TOAST });
  };

  return (
    <div className="flex flex-col py-10 px-12 w-full bg-white relative shadow-modal-shadow rounded-[16px] md:min-w-[719px]">
      <CrossIcon
        onClick={closeModal}
        className="h-10 w-10 cursor-pointer  text-neutral-400 absolute top-[28px] right-[24px]"
      />
      <h4 className="h4 text-black font-bold mb-10">Share</h4>
      <div className="flex justify-between bg-neutral-200 items-stretch rounded-[8px]">
        <div className="flex gap-4 ">
          <div className="flex items-center border-r border-neutral-300 justify-center min-w-[64px]">
            <LinkIcon />
          </div>
          <p className="body3 flex items-center text-neutral-600 text-start font-semibold ">
            {window.location.href}
          </p>
        </div>
        <Button
          className="bg-white rounded-lg hover:bg-neutral-300 disabled:cursor-not-allowed border-2 border-neutral-300 body3 m-2 font-semibold text-black px-4 py-3"
          onClick={handleCopy}
        >
          Copy link
        </Button>
      </div>
    </div>
  );
}
