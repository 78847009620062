import DRepImageSelection from "@src/components/atoms/imageSelection";
import DRepIDGivenNameArea from "@src/components/atoms/givenNameArea";
import { IDRepFormField } from "@src/models/dtos/metadata";
import { ChangeEvent } from "react";

export default function StepOne({
  setDRepRegistrationFormData,
  dRepRegistrationFormData,
}: {
  setDRepRegistrationFormData: (
    value: (prev: IDRepFormField) => IDRepFormField,
  ) => void;
  dRepRegistrationFormData: IDRepFormField;
}) {
  const handleOnChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    const { id, value } = e.target;
    setDRepRegistrationFormData((prevState: IDRepFormField) => ({
      ...prevState,
      [id]: value,
    }));
  };
  const handleImageSelect = (image: File | string) => {
    setDRepRegistrationFormData((prevState: IDRepFormField) => ({
      ...prevState,
      image,
    }));
  };
  return (
    <div className="flex flex-col gap-6 w-full">
      <DRepIDGivenNameArea
        inputCharacter={dRepRegistrationFormData.givenName?.length || 0}
        id="givenName"
        label="Name"
        value={dRepRegistrationFormData.givenName}
        onChange={handleOnChange}
        placeholder="Enter your name"
      />
      <DRepImageSelection
        selectedImage={dRepRegistrationFormData.image}
        onImageSelect={handleImageSelect}
      />
    </div>
  );
}
