import CrossIcon from "@src/components/icons/cross";
import { useModal } from "../context";
import Stepper from "@src/components/atoms/stepper";
import { useState } from "react";
import { Button } from "@headlessui/react";
import StepOne from "@src/components/molecules/drep-registration-step/stepOne";
import { IAnchor, IDRepFormField } from "@src/models/dtos/metadata";
import { usePathname } from "next/navigation";
import StepTwo from "@src/components/molecules/drep-registration-step/stepTwo";
import StepThree from "@src/components/molecules/drep-registration-step/stepThree";
import StepFour from "@src/components/molecules/drep-registration-step/stepFour";
import { useAppDispatch, useAppSelector } from "@src/store/hooks";
import {
  selectPendingTransactions,
  setPendingTransactions,
} from "@src/store/transaction/transaction";
import { RootState } from "@src/store/store";
import { getCip30Wallet } from "@src/utils/kuberUtils";
import { dRepRegistration, updateDRep } from "@src/lib/kuber-service";
import { blake2bHash } from "@src/utils/stringUtils";
import { toast } from "react-toastify";
import {
  useUploadImageMutation,
  useUploadMetadataMutation,
} from "@src/store/metadata/api";
import {
  calculateFileHashFromValue,
  convertFormFieldToDRepMetadataBody,
  generateMetadata,
} from "@src/utils/metadataUtils";
import { isEmpty } from "lodash";
import { IError } from "@src/models/dtos";
import { ILoginRequestBody } from "@src/store/drep/types";
import { getDRepIdFromCip30Wallet } from "@src/utils/dRepUtils";
import { bech32 } from "bech32";
import { useDRepLoginMutation } from "@src/store/drep/api";
import { ToastId } from "@src/constants/toastId";
import cn from "classnames";
import withWalletEventListener from "@src/walletEvent/walletStateUtils";
import StepFive from "@src/components/molecules/drep-registration-step/stepFive";

function MetadataForm({
  dRepFormData,
  isEditDRep = false,
}: {
  dRepFormData?: IDRepFormField;
  isEditDRep?: boolean;
}) {
  const { closeModal } = useModal();
  const [step, setStep] = useState(1);
  const pendingTransactions = useAppSelector(selectPendingTransactions);
  const wallet = useAppSelector((state: RootState) => state.wallet);
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [uploadMetadata] = useUploadMetadataMutation();
  const [uploadImage] = useUploadImageMutation();
  const [dRepLogin] = useDRepLoginMutation();
  const path = usePathname();
  const tokenName = decodeURIComponent(path?.replace("/", "") as string);
  const [paymentAddressError, setPaymentAddressError] = useState<string>("");
  const [identityLinksError, setIdentityLinksError] = useState<IError>();
  const [linkError, setLinkError] = useState<IError>();
  const stepperLabels = [
    "Profile",
    "About",
    "Payment address",
    "Social media links",
    "Identity",
  ];
  const [dRepRegistrationFormData, setDRepRegistrationFormData] =
    useState<IDRepFormField>(
      dRepFormData || {
        givenName: "",
        image: undefined,
        motivations: "",
        objectives: "",
        paymentAddress: "",
        qualifications: "",
        references: [],
      },
    );

  const isFirstStep = step === 1;
  const isLastStep = step === 5;

  const handleNextStep = async () => {
    if (!isLastStep) {
      setStep(step + 1);
    } else {
      await handleDRepRegister();
    }
  };

  const handlePreviousStep = () => {
    if (!isFirstStep) {
      setStep(step - 1);
    }
  };

  const getStepperStatus = (currentStep: number) => {
    if (step === currentStep) return "Active";
    if (step > currentStep) return "Completed";
    return "Inactive";
  };
  const uploadMetadataAndGetUrlWithFileHash = async (
    dRepFormField: IDRepFormField,
  ) => {
    try {
      const getImageUrl = async (image: File) => {
        const response = await uploadImage({
          dRepName: tokenName,
          imageFile: image,
        }).unwrap();
        return response.url;
      };

      const uploadMetadataWithHash = async (metadataBody: any) => {
        const jsonMetadata = generateMetadata(metadataBody);
        const response = await uploadMetadata({
          dRepName: tokenName,
          requestBody: {
            commitMessage: `Upload file ${tokenName}`,
            data: jsonMetadata,
          },
        }).unwrap();
        return {
          url: response.url,
          dataHash: calculateFileHashFromValue(jsonMetadata),
        };
      };

      const handleLoginAndRetry = async () => {
        if (!wallet?.instance) {
          toast.error("Wallet is not connected.", {
            toastId: ToastId.ERROR_TOAST,
          });
          return null;
        }

        const kuberClientWallet = await getCip30Wallet(wallet);
        if (!kuberClientWallet) {
          console.error("Error enabling wallet.");
          return null;
        }

        const enabledWallet = kuberClientWallet.instance;
        const expirationDate = new Date();
        expirationDate.setDate(expirationDate.getDate() + 7);
        const isoDateString = expirationDate.toISOString().slice(0, 10);
        const messageUTF8 = `valid until ${isoDateString}`;

        const drepIdBech32 = await getDRepIdFromCip30Wallet(kuberClientWallet);
        const drepIdHex = Buffer.from(
          bech32.fromWords(bech32.decode(drepIdBech32 as string, 100).words),
        ).toString("hex");

        const dRepLoginRequestBody: ILoginRequestBody =
          await enabledWallet.signData(
            drepIdHex.slice(-56),
            Buffer.from(messageUTF8, "utf-8").toString("hex"),
          );

        const response = await dRepLogin(dRepLoginRequestBody);
        if ("data" in response && response.data) {
          console.log(response.data);
        } else {
          toast.error("Login Failed. Please try again", {
            toastId: ToastId.ERROR_TOAST,
          });
          handleLoginAndRetry();
        }
        return true;
      };

      const handleMetadataUpload = async () => {
        let imageUrl;
        if (dRepFormField?.image instanceof File) {
          imageUrl = await getImageUrl(dRepFormField.image);
        } else if (typeof dRepFormField?.image == "string") {
          imageUrl = dRepFormField?.image;
        } else if (
          typeof dRepFormField?.image === "object" &&
          dRepFormField?.image?.["@type"] === "ImageObject" &&
          typeof dRepFormField?.image?.["contentUrl"] === "string" &&
          typeof dRepFormField?.image?.["sha256"] === "string" &&
          Object.keys(dRepFormField?.image).length === 3
        ) {
          imageUrl = dRepFormField?.image?.["contentUrl"];
        }
        const metadataBody = await convertFormFieldToDRepMetadataBody(
          dRepFormField,
          dRepFormField.givenName,
          imageUrl,
        );
        return await uploadMetadataWithHash(metadataBody);
      };

      try {
        return await handleMetadataUpload();
      } catch (e: any) {
        if (
          e?.data &&
          typeof e.data === "string" &&
          (e.data.toLowerCase().includes("missing cookie") ||
            e.data.toLowerCase().includes("expired"))
        ) {
          if (await handleLoginAndRetry()) {
            return await handleMetadataUpload();
          }
        } else {
          toast.error(`Failed to upload metadata. ${e.data.error || ""}`, {
            toastId: ToastId.ERROR_TOAST,
          });
          return null;
        }
      }
    } catch (error: any) {
      console.error(`${error.message || error.data || error}`);
      toast.error("An unexpected error occurred.", {
        toastId: ToastId.ERROR_TOAST,
      });
      return null;
    }
  };

  const generateAnchor = async (dRepFormField: IDRepFormField) => {
    const value = await uploadMetadataAndGetUrlWithFileHash(dRepFormField);
    if (value && value.dataHash) {
      return { url: value.url, dataHash: value.dataHash } as IAnchor;
    }
    toast.error("Failed to generate anchor", { toastId: ToastId.ERROR_TOAST });
  };

  const handleDRepRegister = async () => {
    setIsLoading(true);
    if (wallet && wallet.instance) {
      try {
        const kuberClientWallet = await getCip30Wallet(wallet);
        if (!kuberClientWallet) {
          console.error("Error Enabling Wallet");
        } else {
          const dRepPubKey =
            (await kuberClientWallet.instance.cip95?.getPubDRepKey()) || "";
          const anchor = await generateAnchor(dRepRegistrationFormData);
          if (!anchor) return;

          const dRepId = "22" + blake2bHash(dRepPubKey);
          const stakeDelegationResponse = isEditDRep
            ? await updateDRep(kuberClientWallet, dRepId, anchor)
            : await dRepRegistration(kuberClientWallet, dRepId, anchor);
          const signTx = await kuberClientWallet.getSignedTx(
            stakeDelegationResponse.cborHex,
          );
          await kuberClientWallet.submitTx(signTx).then((txId) => {
            if (txId) {
              dispatch(
                setPendingTransactions({
                  ...pendingTransactions,
                  pendingDRepRegistrationTransaction: {
                    txId: txId as string,
                    name: tokenName,
                    time: Date.now(),
                  },
                }),
              );
            }
          });
        }
      } catch (e: any) {
        console.log(e);
        toast.error(`Failed to register dRep due to ${e.message || e.data}`, {
          toastId: ToastId.ERROR_TOAST,
        });
      } finally {
        closeModal();
        setIsLoading(false);
      }
    }
  };

  return (
    <div className="flex flex-col w-full bg-white relative shadow-modal-shadow rounded-[16px] max-w-[858px] md:min-w-[858px]">
      <CrossIcon
        onClick={closeModal}
        className="h-10 w-10 cursor-pointer  text-neutral-400 absolute top-[28px] right-[24px]"
      />
      <div className="flex flex-col px-11 py-12">
        <p className="h4">{isEditDRep ? "Edit Profile" : "Add profile"}</p>
        <div className="mt-6 mb-14 flex w-full">
          {stepperLabels.map((label, index) => (
            <Stepper
              key={label}
              status={getStepperStatus(index + 1)}
              label={label}
              className={index > 0 ? "flex-1" : ""}
            />
          ))}
        </div>
        {step === 1 && (
          <StepOne
            setDRepRegistrationFormData={setDRepRegistrationFormData}
            dRepRegistrationFormData={dRepRegistrationFormData}
          />
        )}
        {step === 2 && (
          <StepTwo
            setDRepRegistrationFormData={setDRepRegistrationFormData}
            dRepRegistrationFormData={dRepRegistrationFormData}
          />
        )}
        {step === 3 && (
          <StepThree
            setError={setPaymentAddressError}
            error={paymentAddressError}
            setDRepRegistrationFormData={setDRepRegistrationFormData}
            dRepRegistrationFormData={dRepRegistrationFormData}
          />
        )}
        {step === 4 && (
          <StepFour
            setError={setIdentityLinksError}
            error={identityLinksError}
            setDRepRegistrationFormData={setDRepRegistrationFormData}
            dRepRegistrationFormData={dRepRegistrationFormData}
          />
        )}
        {step === 5 && (
          <StepFive
            setError={setLinkError}
            error={linkError}
            setDRepRegistrationFormData={setDRepRegistrationFormData}
            dRepRegistrationFormData={dRepRegistrationFormData}
          />
        )}

        <div className="flex justify-end gap-6 mt-20">
          {!isFirstStep && (
            <Button
              disabled={isLoading && isLastStep}
              onClick={handlePreviousStep}
              className="btn-ghost"
            >
              Back
            </Button>
          )}
          <Button
            onClick={handleNextStep}
            disabled={
              (isLoading && isLastStep) ||
              (step === 3 &&
                !!identityLinksError &&
                identityLinksError.errors &&
                identityLinksError.errors.some(
                  (error) => !isEmpty(error.message),
                )) ||
              !isEmpty(paymentAddressError) ||
              (step === 4 &&
                !!linkError &&
                linkError.errors &&
                linkError.errors.some((error) => !isEmpty(error.message)))
            }
            className={cn(
              "btn-primary",
              isLoading && isLastStep && "!border-2 border-warning-700 italic",
            )}
          >
            {isLastStep
              ? !isLoading
                ? "Submit and Complete Transaction"
                : "Processing..."
              : "Next"}
          </Button>
        </div>
      </div>
    </div>
  );
}

export default withWalletEventListener(MetadataForm);
