import React from "react";

export default function MenuIcon(props: React.SVGAttributes<{}>) {
  return (
    <svg
      width="7"
      height="27"
      viewBox="0 0 7 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.01648 20.1099C3.8165 20.1099 4.58375 20.4277 5.14945 20.9934C5.71515 21.5591 6.03296 22.3263 6.03296 23.1263C6.03296 23.9264 5.71515 24.6936 5.14945 25.2593C4.58375 25.825 3.8165 26.1428 3.01648 26.1428C2.21646 26.1428 1.44921 25.825 0.883506 25.2593C0.317807 24.6936 0 23.9264 0 23.1263C0 22.3263 0.317807 21.5591 0.883506 20.9934C1.44921 20.4277 2.21646 20.1099 3.01648 20.1099ZM3.01648 10.0549C3.8165 10.0549 4.58375 10.3727 5.14945 10.9384C5.71515 11.5041 6.03296 12.2714 6.03296 13.0714C6.03296 13.8714 5.71515 14.6387 5.14945 15.2044C4.58375 15.7701 3.8165 16.0879 3.01648 16.0879C2.21646 16.0879 1.44921 15.7701 0.883506 15.2044C0.317807 14.6387 0 13.8714 0 13.0714C0 12.2714 0.317807 11.5041 0.883506 10.9384C1.44921 10.3727 2.21646 10.0549 3.01648 10.0549ZM3.01648 0C3.8165 0 4.58375 0.317806 5.14945 0.883506C5.71515 1.44921 6.03296 2.21646 6.03296 3.01648C6.03296 3.8165 5.71515 4.58375 5.14945 5.14945C4.58375 5.71515 3.8165 6.03296 3.01648 6.03296C2.21646 6.03296 1.44921 5.71515 0.883506 5.14945C0.317807 4.58375 0 3.8165 0 3.01648C0 2.21646 0.317807 1.44921 0.883506 0.883506C1.44921 0.317806 2.21646 0 3.01648 0Z"
        fill="currentColor"
      />
    </svg>
  );
}
