import React, { useEffect, useMemo, useRef, useState } from "react";
import cn from "classnames";
import { isEmptyString } from "@src/utils/stringUtils";
import { debounce } from "lodash";
import { toast } from "react-toastify";
import { ToastId } from "@src/constants/toastId";

type AutocompleteSearchProps<T> = {
  searchQuery: (term: string) => Promise<T[]>;
  placeholder?: string;
  renderItem: (item: T, index: number) => React.ReactNode;
  onItemClick: (item: T) => void;
  debounceDelay?: number;
};

const AutocompleteSearch = <T extends unknown>({
  searchQuery,
  placeholder = "Search...",
  renderItem,
  onItemClick,
  debounceDelay = 500,
}: AutocompleteSearchProps<T>) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [data, setData] = useState<T[]>([]);
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);

  const handleInputChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const { value } = event.target;
    setIsOpen(true);
    setSearchTerm(value);
    if (!isEmptyString(value)) {
      try {
        const results = await searchQuery(value);
        setData(results);
      } catch (e) {
        toast.error("Failed to fetch search results", {
          toastId: ToastId.ERROR_TOAST,
        });
      }
    } else {
      setData([]);
    }
  };

  const debouncedSearch = useMemo(
    () => debounce(handleInputChange, debounceDelay),
    [debounceDelay],
  );

  return (
    <div className="relative">
      <input
        type="text"
        className="input-field p-3 xl:min-w-[550px] min-w-[400px]"
        placeholder={placeholder}
        onChange={debouncedSearch}
      />
      {data.length > 0 && isOpen && (
        <div
          ref={ref}
          className={cn(
            "absolute bg-neutral-50 flex flex-col max-h-[500px] overflow-scroll top-14 w-full rounded-md",
            isEmptyString(searchTerm) && "hidden",
          )}
        >
          {data.map((item, index) => (
            <div
              onClick={() => {
                onItemClick(item);
                setIsOpen(false);
              }}
              className={cn(
                "flex flex-col p-4  hover:bg-primary-200 cursor-pointer",
                index !== data.length - 1 && "border-neutral-400 border-b",
              )}
              key={index}
            >
              {renderItem(item, index)}
            </div>
          ))}
        </div>
      )}
      {data.length === 0 && !isEmptyString(searchTerm) && (
        <div className="absolute bg-neutral-50 flex flex-col max-h-[500px] overflow-scroll top-14 w-full rounded-md">
          <p className="text-neutral-600 py-10 w-full body3 text-center">
            Not found
          </p>
        </div>
      )}
    </div>
  );
};

export default AutocompleteSearch;
