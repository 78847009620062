import React from "react";
import AutocompleteSearch from "@src/components/atoms/autoCompleteSearch";
import { useLazySearchQuery } from "@src/store/search/api";
import { useRouter } from "next/navigation";
import { searchLabel } from "@src/constants/searchLabel";
import { getValueByPath } from "@src/utils/metadataUtils";

const DRepSearch = () => {
  const [searchQuery] = useLazySearchQuery();
  const router = useRouter();

  const handleSearchQuery = async (term: string) => {
    const result = await searchQuery(term).unwrap();
    return result;
  };

  const handleItemClick = (item: any) => {
    router.push(`/drep/${item._source.meta.drepIdCip105}`);
  };

  return (
    <AutocompleteSearch
      searchQuery={handleSearchQuery}
      placeholder="Search DReps..."
      renderItem={(item, index) => (
        <div key={index}>
          <p className="truncate flex gap-1 tiny-text">
            <span className="font-bold">Given Name : </span>
            <span className="flex-1">{item._source.givenName}</span>
          </p>
          <p className="truncate flex gap-1 tiny-text">
            <span className="font-bold">DRep ID (CIP 105) : </span>
            <span className="flex-1">{item._source.meta.drepIdCip105}</span>
          </p>
          {Object.keys(item.highlight).map((key) => (
            <p key={key} className="tiny-text flex gap-1">
              <span className="font-medium  text-neutral-600">
                {getValueByPath(searchLabel, key)} :
              </span>
              <span
                className="break-words flex-1 search-value"
                dangerouslySetInnerHTML={{
                  //@ts-ignore
                  __html: item.highlight[key].toString(),
                }}
              ></span>
            </p>
          ))}
        </div>
      )}
      onItemClick={handleItemClick}
    />
  );
};

export default DRepSearch;
