import React from "react";

export default function Love(props: React.SVGAttributes<{}>) {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12.001 5.02857C13.1438 4.00623 14.6346 3.4602 16.1674 3.5026C17.7001 3.545 19.1585 4.17262 20.243 5.25657C21.3264 6.33976 21.9543 7.79632 21.9982 9.32766C22.0421 10.859 21.4986 12.3491 20.479 13.4926L11.999 21.9846L3.52102 13.4926C2.50028 12.3485 1.95623 10.8571 2.00056 9.32456C2.04489 7.79198 2.67425 6.33453 3.75942 5.2514C4.84459 4.16828 6.30323 3.54168 7.83589 3.50024C9.36854 3.4588 10.8589 4.00567 12.001 5.02857Z"
        fill="#EB4545"
      />
    </svg>
  );
}
