import { useRef } from "react";
import { BiSolidImageAdd } from "react-icons/bi";
import { Button } from "@headlessui/react";

export default function DRepImageSelection({
  selectedImage,
  onImageSelect,
}: {
  selectedImage: File | string | undefined;
  onImageSelect: (image: File | string) => void;
}) {
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      onImageSelect(file);
    }
  };

  const handleImageFileSelection = (event: React.MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
    fileInputRef.current?.click();
  };

  const handleImageUploadCancelation = (event: React.MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
    onImageSelect("");
  };

  return (
    <label className="flex flex-col relative gap-2">
      <span className="body2 font-semibold text-neutral-600">Image</span>
      {selectedImage ? (
        <div className="flex flex-col items-center gap-2">
          <img
            src={
              selectedImage instanceof File
                ? URL.createObjectURL(selectedImage)
                : selectedImage
            }
            alt="Selected File"
            className="w-80 h-80 object-cover rounded-md"
          />
          <div className="flex flex-row gap-3">
            <Button onClick={handleImageFileSelection}>
              {" "}
              <span className="text-md text-neutral-600 underline">Change</span>
            </Button>
            <Button onClick={handleImageUploadCancelation}>
              {" "}
              <span className="text-md text-neutral-600 underline">Remove</span>
            </Button>
          </div>
        </div>
      ) : (
        <span className="w-1/12 flex btn-primary justify-center">
          <span className="h3">
            <BiSolidImageAdd onClick={handleImageFileSelection} />
          </span>
        </span>
      )}
      <input
        type="file"
        accept="image/*"
        ref={fileInputRef}
        onChange={handleFileChange}
        style={{ display: "none" }}
      />
    </label>
  );
}
