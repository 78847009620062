"use client";

import React from "react";
import cn from "classnames";
import Navbar from "@src/layouts/navbar";
import Footer from "@src/layouts/footer";
import { ScrollProvider } from "@src/context/scroll-context";
import { usePathname } from "next/navigation";
import { Provider } from "react-redux";
import { store } from "@src/store/store";
import ModalContainer from "@src/components/modal-views/container";

interface ILayoutProps {
  className?: string;
}

export default function Layout({
  children,
  className,
}: React.PropsWithChildren<ILayoutProps>) {
  const pathname = usePathname();

  return (
    <Provider store={store}>
      <ScrollProvider>
        <div className="dark:bg-dark bg-white z-20 w-full min-h-screen ">
          <Navbar />
          <main
            className={cn(
              `flex h-full min-h-calc-548 w-full flex-col items-center justify-center `,
              className,
            )}
          >
            {children}
          </main>
          {pathname === "/" && <Footer />}
        </div>
      </ScrollProvider>
      <ModalContainer />
    </Provider>
  );
}
