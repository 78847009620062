import React from "react";

export default function Logo(props: React.SVGAttributes<{}>) {
  return (
    <svg
      width="110"
      height="36"
      viewBox="0 0 110 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M0 24.072V7.27197H7.632C9.456 7.27197 11.064 7.62397 12.456 8.32797C13.848 9.01597 14.936 9.98397 15.72 11.232C16.504 12.48 16.896 13.96 16.896 15.672C16.896 17.368 16.504 18.848 15.72 20.112C14.936 21.36 13.848 22.336 12.456 23.04C11.064 23.728 9.456 24.072 7.632 24.072H0ZM3.888 20.88H7.44C8.56 20.88 9.528 20.672 10.344 20.256C11.176 19.824 11.816 19.216 12.264 18.432C12.728 17.648 12.96 16.728 12.96 15.672C12.96 14.6 12.728 13.68 12.264 12.912C11.816 12.128 11.176 11.528 10.344 11.112C9.528 10.68 8.56 10.464 7.44 10.464H3.888V20.88Z"
        fill="#6B1DD8"
      />
      <path
        d="M19.8281 24.072V7.27197H27.1001C28.6041 7.27197 29.9001 7.51997 30.9881 8.01597C32.0761 8.49597 32.9161 9.19197 33.5081 10.104C34.1001 11.016 34.3961 12.104 34.3961 13.368C34.3961 14.616 34.1001 15.696 33.5081 16.608C32.9161 17.504 32.0761 18.192 30.9881 18.672C29.9001 19.152 28.6041 19.392 27.1001 19.392H21.9881L23.7161 17.688V24.072H19.8281ZM30.5081 24.072L26.3081 17.976H30.4601L34.7081 24.072H30.5081ZM23.7161 18.12L21.9881 16.296H26.8841C28.0841 16.296 28.9801 16.04 29.5721 15.528C30.1641 15 30.4601 14.28 30.4601 13.368C30.4601 12.44 30.1641 11.72 29.5721 11.208C28.9801 10.696 28.0841 10.44 26.8841 10.44H21.9881L23.7161 8.59197V18.12Z"
        fill="#6B1DD8"
      />
      <path
        d="M43.6206 24.264C42.1486 24.264 40.8526 23.976 39.7326 23.4C38.6286 22.824 37.7726 22.04 37.1646 21.048C36.5566 20.04 36.2526 18.896 36.2526 17.616C36.2526 16.32 36.5486 15.176 37.1406 14.184C37.7486 13.176 38.5726 12.392 39.6126 11.832C40.6526 11.256 41.8286 10.968 43.1406 10.968C44.4046 10.968 45.5406 11.24 46.5486 11.784C47.5726 12.312 48.3806 13.08 48.9726 14.088C49.5646 15.08 49.8606 16.272 49.8606 17.664C49.8606 17.808 49.8526 17.976 49.8366 18.168C49.8206 18.344 49.8046 18.512 49.7886 18.672H39.3006V16.488H47.8206L46.3806 17.136C46.3806 16.464 46.2446 15.88 45.9726 15.384C45.7006 14.888 45.3246 14.504 44.8446 14.232C44.3646 13.944 43.8046 13.8 43.1646 13.8C42.5246 13.8 41.9566 13.944 41.4606 14.232C40.9806 14.504 40.6046 14.896 40.3326 15.408C40.0606 15.904 39.9246 16.496 39.9246 17.184V17.76C39.9246 18.464 40.0766 19.088 40.3806 19.632C40.7006 20.16 41.1406 20.568 41.7006 20.856C42.2766 21.128 42.9486 21.264 43.7166 21.264C44.4046 21.264 45.0046 21.16 45.5166 20.952C46.0446 20.744 46.5246 20.432 46.9566 20.016L48.9486 22.176C48.3566 22.848 47.6126 23.368 46.7166 23.736C45.8206 24.088 44.7886 24.264 43.6206 24.264Z"
        fill="#6B1DD8"
      />
      <path
        d="M59.9852 24.264C58.8972 24.264 57.9452 24.024 57.1292 23.544C56.3132 23.064 55.6732 22.336 55.2092 21.36C54.7612 20.368 54.5372 19.12 54.5372 17.616C54.5372 16.096 54.7532 14.848 55.1852 13.872C55.6172 12.896 56.2412 12.168 57.0572 11.688C57.8732 11.208 58.8492 10.968 59.9852 10.968C61.2012 10.968 62.2892 11.248 63.2492 11.808C64.2252 12.352 64.9932 13.12 65.5532 14.112C66.1292 15.104 66.4172 16.272 66.4172 17.616C66.4172 18.976 66.1292 20.152 65.5532 21.144C64.9932 22.136 64.2252 22.904 63.2492 23.448C62.2892 23.992 61.2012 24.264 59.9852 24.264ZM52.3532 28.728V11.16H55.9292V13.8L55.8572 17.64L56.0972 21.456V28.728H52.3532ZM59.3372 21.192C59.9612 21.192 60.5132 21.048 60.9932 20.76C61.4892 20.472 61.8812 20.064 62.1692 19.536C62.4732 18.992 62.6252 18.352 62.6252 17.616C62.6252 16.864 62.4732 16.224 62.1692 15.696C61.8812 15.168 61.4892 14.76 60.9932 14.472C60.5132 14.184 59.9612 14.04 59.3372 14.04C58.7132 14.04 58.1532 14.184 57.6572 14.472C57.1612 14.76 56.7692 15.168 56.4812 15.696C56.1932 16.224 56.0492 16.864 56.0492 17.616C56.0492 18.352 56.1932 18.992 56.4812 19.536C56.7692 20.064 57.1612 20.472 57.6572 20.76C58.1532 21.048 58.7132 21.192 59.3372 21.192Z"
        fill="#6B1DD8"
      />
      <rect x="70.4172" width="39" height="36" rx="8" fill="#6B1DD8" />
      <path
        d="M79.8572 25V14.24H82.9772V25H79.8572ZM81.4172 12.74C80.8439 12.74 80.3772 12.5733 80.0172 12.24C79.6572 11.9067 79.4772 11.4933 79.4772 11C79.4772 10.5067 79.6572 10.0933 80.0172 9.76C80.3772 9.42667 80.8439 9.26 81.4172 9.26C81.9906 9.26 82.4572 9.42 82.8172 9.74C83.1772 10.0467 83.3572 10.4467 83.3572 10.94C83.3572 11.46 83.1772 11.8933 82.8172 12.24C82.4706 12.5733 82.0039 12.74 81.4172 12.74ZM86.0929 25V11H92.4529C93.9729 11 95.3129 11.2933 96.4729 11.88C97.6329 12.4533 98.5395 13.26 99.1929 14.3C99.8462 15.34 100.173 16.5733 100.173 18C100.173 19.4133 99.8462 20.6467 99.1929 21.7C98.5395 22.74 97.6329 23.5533 96.4729 24.14C95.3129 24.7133 93.9729 25 92.4529 25H86.0929ZM89.3329 22.34H92.2929C93.2262 22.34 94.0329 22.1667 94.7129 21.82C95.4062 21.46 95.9395 20.9533 96.3129 20.3C96.6995 19.6467 96.8929 18.88 96.8929 18C96.8929 17.1067 96.6995 16.34 96.3129 15.7C95.9395 15.0467 95.4062 14.5467 94.7129 14.2C94.0329 13.84 93.2262 13.66 92.2929 13.66H89.3329V22.34Z"
        fill="#F6F6F6"
      />
    </svg>
  );
}
