"use client";
import environments from "@src/configs/environments";

export default function DRepIdScript() {
  if (process.env.NEXT_PUBLIC_NODE_ENV === "production")
    return (
      <>
        {environments.UMAMI_WEBSITE_ID && (
          <script
            defer
            src="/script.js"
            data-website-id={environments.UMAMI_WEBSITE_ID}
          ></script>
        )}
      </>
    );
}
