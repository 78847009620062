import AnchorLink from "@src/components/atoms/links/anchor-link";
import Logo from "@src/components/icons/logo";
import Love from "@src/components/icons/love";
import NepalFlag from "@src/components/icons/nepal";
import { useScrollContext } from "@src/context/scroll-context";
import { useParams, useRouter } from "next/navigation";

export default function Footer() {
  const { scrollToSection, howItWorksRef, featuresRef } = useScrollContext();
  const router = useRouter();
  const params = useParams();

  const handleNavigation = (ref: React.RefObject<HTMLDivElement>) => {
    if (params?.page !== undefined) {
      router.replace("/");
    }
    scrollToSection(ref);
  };
  return (
    <div className="flex w-full items-center justify-center ">
      <div className="body3 flex flex-col w-full max-w-[1440px] flex-wrap items-center text-neutral-700 px-6 sm:px-[157px] ">
        <div className="flex sm:flex-row flex-col justify-between w-full sm:py-[127px] py-[60px] gap-10">
          <div className="flex flex-col gap-6 max-w-[423px]">
            <AnchorLink aria-label="navigate to landing page" href="/">
              <Logo />
            </AnchorLink>
            <p className="text-neutral-700 body3">
              DRep ID is a decentralized platform helping users create unique,
              URL-friendly identities for governance and decentralized voting.
            </p>
          </div>
          <div className="flex sm:flex-row flex-col sm:gap-[96px] gap-10">
            <div className="flex flex-col gap-6">
              <p className="text-neutral-900 body2 font-semibold">
                Quick Links
              </p>
              <div className="flex flex-col body3 gap-4">
                <p
                  className="cursor-pointer"
                  onClick={() => router.replace("/")}
                >
                  Home
                </p>
                <p
                  className="cursor-pointer"
                  onClick={() => handleNavigation(howItWorksRef)}
                >
                  How it works
                </p>
                <p
                  className="cursor-pointer"
                  onClick={() => handleNavigation(featuresRef)}
                >
                  Features
                </p>
              </div>
            </div>
            <div className="flex flex-col gap-6">
              <p className="text-neutral-900 body2 font-semibold">Company</p>
              <div className="flex flex-col body3 gap-4">
                <AnchorLink
                  href="https://docs.drep.id/privacy-policy/"
                  target="_blank"
                >
                  Privacy Policy
                </AnchorLink>
                <AnchorLink
                  href="https://docs.drep.id/terms-of-service/"
                  target="_blank"
                >
                  Terms and Services
                </AnchorLink>
              </div>
            </div>
          </div>
        </div>
        <div className="flex sm:flex-row flex-col items-start min-h-[55px] tiny-text w-full justify-between">
          <p>&copy; 2024 Sireto Technology. All rights reserved.</p>
          <div className="flex gap-1 items-center">
            <span>Build with</span>
            <Love />
            <span>from</span> <NepalFlag />
          </div>
        </div>
      </div>
    </div>
  );
}
