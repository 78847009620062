import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react";
import React, { useState } from "react";
import ChevronDown from "@src/components/icons/chevronDown";
import cn from "classnames";
import { capitalize } from "lodash";
import MenuIcon from "@src/components/icons/menu";

export interface IMenuItem {
  label: string;
  onClick: () => void;
  icon?: React.ReactElement;
  className?: string;
}

interface IDropdown {
  title?: string;
  isDropDownIconEnable?: boolean;
  prefixIcon?: React.ReactElement;
  menuItems: IMenuItem[];
  className?: string;
  contentWidth?: number;
  disabled?: boolean;
}

export default function Dropdown({
  title,
  menuItems,
  isDropDownIconEnable = true,
  prefixIcon,
  className,
  disabled = false,
  contentWidth = 168,
}: IDropdown) {
  const [isRotate, setRotate] = useState(false);
  return (
    <Menu>
      <MenuButton
        disabled={disabled}
        onClick={() => setRotate(!isRotate)}
        className={cn(
          "flex items-center body3 text-neutral-900 shadow-wallet-button-shadow px-[10px] py-[6px] border-2 bg-white border-neutral-200 rounded-[4px]",
          className,
          disabled && "opacity-40 cursor-not-allowed",
        )}
      >
        {prefixIcon && <div className="pr-2">{prefixIcon}</div>}
        {title ? capitalize(title) : <MenuIcon className="text-neutral-600" />}
        {isDropDownIconEnable && (
          <ChevronDown
            className={cn(
              "text-neutral-400  h-5 w-5",
              isRotate ? "rotate-180 pr-[5px]" : "pl-[5px]",
            )}
          />
        )}
      </MenuButton>
      <MenuItems
        transition
        anchor="bottom start"
        style={{ width: `${contentWidth}px` }}
        className=" !z-40 mt-[5px] shadow-dropdown-content-shadow gap-[10px]  border bg-white text-neutral-800 border-neutral-200 rounded-[4px] transition duration-100 ease-out"
      >
        {menuItems.map((item, index) => (
          <MenuItem key={index}>
            <div
              className={cn(
                "flex px-4 items-center hover:bg-neutral-100 py-[10px] border-b border-neutral-200 gap-2 cursor-pointer",
                item.className,
              )}
              onClick={item.onClick}
            >
              {item.icon}
              <p className="text-ellipsis overflow-hidden whitespace-nowrap">
                {item.label}
              </p>
            </div>
          </MenuItem>
        ))}
      </MenuItems>
    </Menu>
  );
}
