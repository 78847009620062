"use client";

import React, { useState, useEffect } from "react";
import AnchorLink from "@src/components/atoms/links/anchor-link";
import Burger from "@src/components/icons/burger";
import { useScrollContext } from "@src/context/scroll-context";
import { useParams, usePathname, useRouter } from "next/navigation";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@src/store/store";
import { removeWallet } from "@src/store/user/wallet";
import { useDRepLogoutMutation } from "@src/store/drep/api";
import Logo from "@src/components/icons/logo";
import Dropdown, { IMenuItem } from "@src/components/atoms/dropdown";
import { useLazyGetDRepByIdQuery } from "@src/store/drep/api";
import { getCip30Wallet } from "@src/utils/kuberUtils";
import { getDRepIdFromCip30Wallet } from "@src/utils/dRepUtils";
import { DRepMetadata } from "@src/store/drep/types";
import cn from "classnames";
import { useModal } from "@src/components/modal-views/context";
import { toast } from "react-toastify";
import { ToastId } from "@src/constants/toastId";
import DRepSearch from "@src/components/molecules/dRepSearch";
import withWalletEventListener from "@src/walletEvent/walletStateUtils";

function Navbar() {
  const [isMenuVisible, setMenuVisible] = useState(false);
  const [dRepLogout] = useDRepLogoutMutation();
  const { scrollToSection, howItWorksRef, featuresRef } = useScrollContext();
  const [getDRepInformationById] = useLazyGetDRepByIdQuery();
  const [isProfileFetching, setProfileFetchingStatus] = useState(true);
  const [tokenList, setTokenList] = useState<Array<string>>([]);
  const router = useRouter();
  const params = useParams();
  const path = usePathname();

  const handleNavigation = (ref: React.RefObject<HTMLDivElement>) => {
    scrollToSection(ref);
    if (isMenuVisible) {
      setMenuVisible(false);
    }
  };

  const { openModal, closeModal } = useModal();
  const [isMounted, setIsMounted] = useState(false);
  const wallet = useSelector((state: RootState) => state.wallet);

  useEffect(() => {
    // This ensures that rendering only happens after the component is mounted on the client.
    // Prevent Hydration error
    setIsMounted(true);
    getTokenList();
  }, [wallet.instance, isMounted]);

  const dispatch = useDispatch();

  const handleWalletDisconnect = async () => {
    if (wallet.instance !== null) {
      try {
        await dRepLogout().unwrap();
        dispatch(removeWallet());
        if (!(params && "dRepName" in params)) {
          router.replace("/");
        }
        closeModal();
      } catch (error) {
        console.error("Failed to logout:", error);
      }
    } else {
      toast.error("wallet connection error", { toastId: ToastId.ERROR_TOAST });
    }
  };

  const getTokenList = async () => {
    if (isMounted && wallet.instance !== null) {
      const walletFromKuber = await getCip30Wallet(wallet);
      if (!walletFromKuber) {
        console.error("Error Enabling Wallet");
        setProfileFetchingStatus(false);
      } else {
        if ((await walletFromKuber.networkId()) != wallet.network) {
          await dRepLogout().unwrap();
          dispatch(removeWallet());
        }
        const dRepId = await getDRepIdFromCip30Wallet(walletFromKuber);
        if (dRepId) {
          try {
            const dRepInformation: DRepMetadata =
              await getDRepInformationById(dRepId).unwrap();
            if (dRepInformation.token && dRepInformation.token.length !== 0) {
              setTokenList(
                dRepInformation.token.map((dRepToken) => dRepToken.name),
              );
            }
          } catch (e) {
            console.log("Failed to fetch the dRep information", e);
          } finally {
            setProfileFetchingStatus(false);
          }
        }
      }
    }
  };

  const profileList: IMenuItem[] = tokenList.map((tokenName) => {
    return {
      label: `@${tokenName}`,
      onClick: () => router.replace(`/${tokenName}`),
      className:
        tokenName.toLowerCase() === (params?.dRepName as string)
          ? "!text-primary-600 !font-bold"
          : "",
    };
  });

  const getNavBarContent = () => (
    <>
      {isMounted && wallet.instance !== null && !isProfileFetching ? (
        <>
          <p
            className={cn(
              "cursor-pointer",
              path === "/drep/mint" && "text-primary-600 font-bold",
            )}
            onClick={() => router.push("/drep/mint")}
          >
            Mint DRep ID
          </p>
        </>
      ) : path === "/" ? (
        <>
          <p
            className="cursor-pointer"
            onClick={() => handleNavigation(howItWorksRef)}
          >
            How it works
          </p>
          <p
            className="cursor-pointer"
            onClick={() => handleNavigation(featuresRef)}
          >
            Features
          </p>
        </>
      ) : (
        <></>
      )}
      {isMounted && wallet.instance !== null && !isProfileFetching && (
        <Dropdown
          title={wallet.name}
          prefixIcon={
            <div className="h-6 w-6 p-[2px]">
              <img
                src={wallet.icon}
                alt="connected wallet icon"
                className="h-full w-full"
              />
            </div>
          }
          menuItems={[
            ...profileList,
            {
              label: "Disconnect Wallet",
              onClick: () =>
                openModal("DISCONNECT_WALLET", {
                  handleDisconnectWallet: handleWalletDisconnect,
                }),
            },
          ]}
        ></Dropdown>
      )}
    </>
  );

  return (
    <nav className="relative top-0 !z-30 flex h-[84px] w-full flex-col items-center justify-center bg-white text-neutral-900 ">
      <div className="flex w-full max-w-[1440px] items-center justify-between gap-10 px-6 sm:px-[120px]">
        <AnchorLink href="/" aria-label="navigate to landing page">
          <Logo />
        </AnchorLink>
        <DRepSearch />
        <div className="body3 hidden items-center gap-10 text-text-primary sm:flex">
          {getNavBarContent()}
        </div>
        {/* <!-- mobile button goes here --> */}
        <div className="flex items-center sm:hidden">
          <Burger
            className="h-6 w-6 cursor-pointer "
            onClick={() => {
              setMenuVisible(!isMenuVisible);
            }}
          />
        </div>
      </div>
      {/* <!-- mobile menu --> */}
      {isMenuVisible && (
        <div className="absolute top-[84px] flex w-full flex-col items-center bg-white gap-8 px-5 py-6 sm:hidden">
          {getNavBarContent()}
        </div>
      )}
    </nav>
  );
}

export default withWalletEventListener(Navbar);
