import cn from "classnames";

type StepStatus = "Active" | "Completed" | "Inactive";

interface IStepTap {
  status: StepStatus;
  label: string;
  isFirstStep?: boolean;
  className?: string;
}

export default function Stepper({
  status,
  label,
  isFirstStep = false,
  className,
}: IStepTap) {
  const getClassNames = () => {
    switch (status) {
      case "Active":
        return {
          parentClassName: "bg-primary-200",
          childClassName: "border border-primary-500 bg-white",
          innerClassName: "bg-primary-500",
        };
      case "Completed":
        return {
          parentClassName: "bg-transparent",
          childClassName: "bg-primary-500 border border-primary-500",
          innerClassName: "bg-neutral-100",
        };
      default: // "Inactive"
        return {
          parentClassName: "bg-transparent",
          childClassName: "border border-neutral-200",
          innerClassName: "bg-neutral-200",
        };
    }
  };

  return (
    <div className={cn("flex items-center h-full relative", className)}>
      {/* Connector line to the left */}
      {!isFirstStep && (
        <div className="flex-grow h-[50px] flex items-center">
          <hr
            className={cn(
              "border w-full ",
              status === "Active" || status === "Completed"
                ? "border-primary-500"
                : "border-neutral-200",
            )}
          />
        </div>
      )}

      <div className="flex flex-col items-center relative">
        {/* Step Circle */}
        <div className={cn("rounded-full", getClassNames().parentClassName)}>
          <div
            className={cn(
              "rounded-full m-[5px]",
              getClassNames().childClassName,
            )}
          >
            <div
              className={cn(
                "rounded-full h-3 m-[10px] w-3",
                getClassNames().innerClassName,
              )}
            ></div>
          </div>
        </div>

        {/* Step label */}
        <p
          className={cn(
            "tiny-text absolute top-[54px] w-[103px] text-center",
            status === "Inactive"
              ? "text-neutral-400"
              : "text-neutral-800 font-semibold",
          )}
        >
          {label}
        </p>
      </div>
    </div>
  );
}
