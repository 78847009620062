export default function ChevronDown(props: React.SVGAttributes<{}>) {
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11.3331 6.6133C11.2082 6.48913 11.0392 6.41943 10.8631 6.41943C10.687 6.41943 10.518 6.48913 10.3931 6.6133L7.99976 8.9733L5.63976 6.6133C5.51485 6.48913 5.34589 6.41943 5.16976 6.41943C4.99364 6.41943 4.82467 6.48913 4.69976 6.6133C4.63728 6.67527 4.58768 6.749 4.55383 6.83024C4.51999 6.91148 4.50256 6.99862 4.50256 7.08663C4.50256 7.17464 4.51999 7.26177 4.55383 7.34301C4.58768 7.42425 4.63728 7.49799 4.69976 7.55996L7.52643 10.3866C7.5884 10.4491 7.66214 10.4987 7.74338 10.5326C7.82462 10.5664 7.91175 10.5838 7.99976 10.5838C8.08777 10.5838 8.17491 10.5664 8.25615 10.5326C8.33739 10.4987 8.41112 10.4491 8.4731 10.3866L11.3331 7.55996C11.3956 7.49799 11.4452 7.42425 11.479 7.34301C11.5129 7.26177 11.5303 7.17464 11.5303 7.08663C11.5303 6.99862 11.5129 6.91148 11.479 6.83024C11.4452 6.749 11.3956 6.67527 11.3331 6.6133Z"
        fill="currentColor"
      />
    </svg>
  );
}
