import CrossIcon from "@src/components/icons/cross";
import { useModal } from "../context";
import { Button } from "@headlessui/react";

interface IDisconnectWalletModal {
  handleDisconnectWallet: () => void;
}
export default function DisconnectWalletModal({
  handleDisconnectWallet,
}: IDisconnectWalletModal) {
  const { closeModal } = useModal();
  return (
    <div className="flex flex-col py-10 px-12 w-full bg-white relative shadow-modal-shadow rounded-[16px] md:min-w-[762px]">
      <CrossIcon
        onClick={closeModal}
        className="h-10 w-10 cursor-pointer  text-neutral-400 absolute top-[28px] right-[24px]"
      />
      <h3 className="h3 text-neutral-900 font-bold">Disconnect Wallet?</h3>
      <h4 className="h4 !font-normal mt-8 mb-12 text-neutral-900">
        Are you sure you want to disconnect wallet?
      </h4>
      <div className="flex items-center gap-6 justify-end">
        <Button onClick={closeModal} className="btn-ghost">
          Cancel
        </Button>
        <Button onClick={handleDisconnectWallet} className="btn-primary">
          Disconnect
        </Button>
      </div>
    </div>
  );
}
