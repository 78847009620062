import { Fragment } from "react";
import { MODAL_VIEW, useModal } from "@src/components/modal-views/context";
import {
  Dialog,
  DialogBackdrop,
  Transition,
  TransitionChild,
} from "@headlessui/react";
import DisconnectWalletModal from "@src/components/modal-views/modals/disconnectWallet";
import RetireDRepModal from "@src/components/modal-views/modals/retireDRep";
import MetadataForm from "@src/components/modal-views/modals/metadataForm";
import ShareModal from "@src/components/modal-views/modals/share";

function renderModalContent(view: MODAL_VIEW | string, modalProps: any) {
  switch (view) {
    case "DISCONNECT_WALLET":
      return <DisconnectWalletModal {...modalProps} />;
    case "RETIRE_DREP":
      return <RetireDRepModal {...modalProps} />;
    case "METADATA_FORM":
      return <MetadataForm {...modalProps} />;
    case "SHARE":
      return <ShareModal />;
    default:
      return <></>;
  }
}

export default function ModalContainer() {
  const { view, isOpen, closeModal, modalProps } = useModal();

  const closeModalHandler = () => {
    closeModal();
  };

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog
        onClose={closeModal}
        className="w-screen h-full relative z-[999] transition overflow-y-auto overflow-x-hidden duration-300 ease-out item"
      >
        <DialogBackdrop className="fixed inset-0 bg-overlay-100 overflow-y-auto overflow-x-hidden flex justify-center items-center">
          <TransitionChild
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-105"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-105"
          >
            <div
              data-testid="modal-view"
              className="relative z-50 max-h-calc-84 inline-block w-full text-left align-middle md:w-fit"
            >
              {view && renderModalContent(view, modalProps)}
            </div>
          </TransitionChild>
        </DialogBackdrop>
      </Dialog>
    </Transition>
  );
}
