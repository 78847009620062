import React, { InputHTMLAttributes } from "react";
import cn from "classnames";
import { isEmpty } from "lodash";
import Info from "@src/components/icons/info";

interface InputFieldProps extends InputHTMLAttributes<HTMLInputElement> {
  label: string;
  className?: string;
  labelClassName?: string;
  error?: string;
  postIcon?: React.ReactElement;
}
export default function DRepIDInputField({
  label,
  className,
  labelClassName,
  error,
  postIcon,
  ...props
}: InputFieldProps) {
  return (
    <label className="flex flex-col gap-2">
      <span
        className={cn("body2 font-semibold text-neutral-600", labelClassName)}
      >
        {label}
      </span>
      <div
        className={cn(
          "h-[62px] flex w-full body2 items-center rounded-[12px] border pr-[18px]  border-neutral-300",
          className,
          !!error &&
            !isEmpty(error) &&
            "!border-error-700 focus:border-error-700",
        )}
      >
        <input
          {...props}
          className={cn(
            " placeholder:text-neutral-400  w-full rounded-[12px] focus:outline-0  focus:border-neutral-400 text-neutral-800 px-6 py-4",
            !!error && !isEmpty(error) && " focus:border-error-700",
          )}
        />
        {!!error && !isEmpty(error) && !postIcon && (
          <Info className="h-6 w-6 text-error-700" />
        )}
        {!!postIcon && postIcon}
      </div>
      {!!error && !isEmpty(error) && (
        <span className="text-error-700 tiny-text">{error}</span>
      )}
    </label>
  );
}
