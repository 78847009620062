import { TextareaHTMLAttributes } from "react";
import cn from "classnames";

interface TextAreaProps extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  label: string;
  className?: string;
  inputCharacter?: number;
}

export default function DRepIDGivenNameArea({
  label,
  className,
  onChange,
  inputCharacter,
  maxLength = 80,
  ...props
}: TextAreaProps) {
  return (
    <label className="flex flex-col relative gap-2">
      <span className="body2 font-semibold text-neutral-600">{label}</span>
      <textarea
        maxLength={maxLength}
        {...props}
        onChange={onChange}
        className={cn(
          "w-full resize-none rounded-[12px] focus:outline-0  focus:border-neutral-400 border border-neutral-300 placeholder:text-neutral-400 text-neutral-800 px-6 py-4",
          className,
        )}
      />
      {inputCharacter != undefined && (
        <p className="body3 text-neutral-300 absolute right-[22px] bottom-[14px] ">
          {inputCharacter}/{maxLength}
        </p>
      )}
    </label>
  );
}
