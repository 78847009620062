import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import environments from "@src/configs/environments";
import { ConfigResponse } from "./types";

export const configApi = createApi({
  refetchOnReconnect: true,
  refetchOnMountOrArgChange: true,
  keepUnusedDataFor: 0,
  baseQuery: fetchBaseQuery({
    baseUrl: environments.BASE_API_URL,
    credentials: "include",
    prepareHeaders: (headers) => {
      headers.append("Content-Type", "application/json");
      return headers;
    },
  }),
  endpoints: (builder) => ({
    config: builder.query<ConfigResponse, void>({
      query: () => ({
        url: `/config`,
        method: "GET",
      }),
    }),
  }),
});

export const { useConfigQuery } = configApi;
