import React from "react";

export default function DeleteIcon(props: React.SVGAttributes<{}>) {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M7.00016 22.1667C7.00016 22.7855 7.246 23.379 7.68358 23.8166C8.12117 24.2542 8.71466 24.5 9.3335 24.5H18.6668C19.2857 24.5 19.8792 24.2542 20.3167 23.8166C20.7543 23.379 21.0002 22.7855 21.0002 22.1667V8.16667H7.00016V22.1667ZM9.3335 10.5H18.6668V22.1667H9.3335V10.5ZM18.0835 4.66667L16.9168 3.5H11.0835L9.91683 4.66667H5.8335V7H22.1668V4.66667H18.0835Z"
        fill="#AAAAAA"
      />
    </svg>
  );
}
