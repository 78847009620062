import { isEmpty } from "lodash";

export const URL_REGEX =
  /^(?:(?:https?:\/\/)?(?:\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}|(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,})(?:\/[^\s]*)?)|(?:ipfs:\/\/[a-f0-9]+(?:\/[a-zA-Z0-9_]+)*)$|^$/;

const MAINNET_BYRON_REGEX = /^(addr1)[A-Za-z0-9]{58}$/; // Byron addresses
const MAINNET_SHELLEY_REGEX = /^(addr1)[A-Za-z0-9]{98}$/; // Shelley addresses
const MAINNET_STAKE_REGEX = /^(stake1)[A-Za-z0-9]{53}$/; // Stake addresses

const TESTNET_BYRON_REGEX = /^(addr_test1)[A-Za-z0-9]{58}$/; // Byron addresses (testnet)
const TESTNET_SHELLEY_REGEX = /^(addr_test1)[A-Za-z0-9]{98}$/; // Shelley addresses (testnet)
const TESTNET_STAKE_REGEX = /^(stake_test1)[A-Za-z0-9]{53}$/; // Stake addresses (testnet)

export function isValidReceivingAddress(address: string) {
  if (isEmpty(address)) {
    return true;
  }
  return (
    MAINNET_BYRON_REGEX.test(address) ||
    MAINNET_SHELLEY_REGEX.test(address) ||
    MAINNET_STAKE_REGEX.test(address) ||
    TESTNET_BYRON_REGEX.test(address) ||
    TESTNET_SHELLEY_REGEX.test(address) ||
    TESTNET_STAKE_REGEX.test(address)
  );
}

export function isValidURLFormat(str: string) {
  if (str.length === 0) return true;
  return URL_REGEX.test(str);
}
